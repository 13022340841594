async function redirectHelper(redirect_uri, idToken, refreshToken, clientState, qsRedirectUri, qsIsNewTab) {
	// Creazione oggetto URL per gestire i parametri in modo automatico
	const url = new URL(redirect_uri, window.location.origin);
	url.searchParams.set("id_token", idToken);
	url.searchParams.set("refresh_token", refreshToken);
	if (clientState) {
		url.searchParams.set("state", clientState);
	}

	const callback_url = url.toString();
	console.log(`callback_url: ${callback_url}`);

	if (qsIsNewTab) {
		console.log("[qsIsNewTab is set up]");
		if (window.parent) {
			console.log("postMessage to parent");
			window.parent.postMessage({ tnOnCloseLoginViewSuccessUrl: callback_url }, new URL(qsRedirectUri).origin);
		}
		if (window.opener && !window.opener.closed) {
			console.log("postMessage to opener");
			window.opener.postMessage({ tnOnCloseLoginViewSuccessUrl: callback_url }, new URL(qsRedirectUri).origin);
			window.close();
		}
	} else {
		window.location.replace(callback_url);
	}
}

export default redirectHelper;